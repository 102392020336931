<template>
  <div class="text-center">
    <img :src="require('../../../../assets/images/12booky.png')" height="80"  alt="">
    <div v-if="isActive">
      <h1 class="mt-3 mb-0">{{ $t('activate.success') }}</h1>
      <p>{{ $t('activate.success_text') }}</p>
      <div class="d-inline-block w-100">
          <router-link :to="{ name: 'app.dashboard' }">
            <button class="btn btn-primary mt-3">
              {{ $t('activate.login') }}
            </button>
          </router-link>
      </div>
    </div>
    <div v-if="!isActive">
      <p>{{ $t('activate.not_found') }}</p>
      <div class="d-inline-block w-100">
        <router-link :to="{ name: 'app.dashboard' }">
          <button class="btn btn-primary mt-3">
            {{ $t('activate.login') }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'

export default {
  name: 'ConfirmMail',
  data () {
    return {
      isActive: false,
      activationToken: this.$route.params.id || ''
    }
  },
  mounted () {
    this.checkIfActive()
  },
  methods: {
    checkIfActive () {
      const dataPost = {
        activationToken: this.activationToken
      }
      Axios.post(API.API_ACTIVATE_ACCOUNT, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isActive = true
          } else {
            this.isActive = false
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    }
  }
}
</script>
